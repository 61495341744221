import { useEffect, useState } from "react"

import { Unsubscribable } from "rxjs"

import { EmberApiError } from "api/errors"
import {
  fetchServiceUpdateSummary,
  fetchServiceUpdates,
} from "api/service-updates"

import { ServiceUpdate, ServiceUpdateSummary } from "types/service-update"

import { paramIsSetToTrue } from "utils/string-utils"
import { useQueryString } from "utils/url-utils"

export const useServiceUpdate = (): {
  serviceUpdateLoaded: boolean
  serviceUpdate: ServiceUpdate | null
  tripUpdates: ServiceUpdate[] | null
  error: EmberApiError | null
} => {
  const [serviceUpdateLoaded, setServiceUpdateLoaded] = useState(false)
  const [serviceUpdate, setServiceUpdate] = useState<ServiceUpdate | null>(null)
  const [tripUpdates, setTripUpdates] = useState<ServiceUpdate[] | null>(null)
  const [error, setError] = useState<EmberApiError | null>(null)
  const [subscription, setSubscription] = useState<Unsubscribable | null>(null)
  const [preview, setPreview] = useState(false)
  const { queryString } = useQueryString()

  useEffect(() => {
    if (queryString == null) {
      // query string hasn't loaded yet, presumably we're mid-hydration
      return
    }
    const params = new URLSearchParams(queryString)
    if (paramIsSetToTrue(params.get("preview")) !== preview) {
      setPreview(paramIsSetToTrue(params.get("preview")))
    }
  }, [queryString])

  useEffect(() => {
    setServiceUpdateLoaded(false)
    if (subscription) subscription.unsubscribe()
    const sub = fetchServiceUpdates({
      preview,
      onSuccess: (response) => {
        if (response.result) {
          setServiceUpdate(response.result)
        }
        if (response.trips && response.trips.length != 0) {
          setTripUpdates(response.trips)
        }
      },
      onError: (e) => {
        setError(e)
      },
      onCompletion: () => setServiceUpdateLoaded(true),
    })
    setSubscription(sub)
  }, [preview])

  return { serviceUpdate, serviceUpdateLoaded, tripUpdates, error }
}

export const useServiceUpdateSummary = (): {
  summaryLoaded: boolean
  summary: ServiceUpdateSummary | null
  error: EmberApiError | null
} => {
  const [summaryLoaded, setSummaryLoaded] = useState(false)
  const [summary, setSummary] = useState<ServiceUpdateSummary | null>(null)
  const [subscription, setSubscription] = useState<Unsubscribable | null>(null)
  const [preview, setPreview] = useState(false)
  const [error, setError] = useState<EmberApiError | null>(null)
  const { queryString } = useQueryString()

  useEffect(() => {
    if (queryString == null) {
      // query string hasn't loaded yet, presumably we're mid-hydration
      return
    }
    const params = new URLSearchParams(queryString)
    if (paramIsSetToTrue(params.get("preview")) !== preview) {
      setPreview(paramIsSetToTrue(params.get("preview")))
    }
  }, [queryString])

  useEffect(() => {
    setSummaryLoaded(false)
    if (subscription) subscription.unsubscribe()

    const sub = fetchServiceUpdateSummary({
      preview,
      onSuccess: (d) => setSummary(d),
      onError: (e) => setError(e),
      onCompletion: () => setSummaryLoaded(true),
    })
    setSubscription(sub)
  }, [preview])

  return { summaryLoaded, summary, error }
}
