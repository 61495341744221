import styled from "styled-components"

// Wrap page/section in this to apply separator below h2 headings – used for legal policies etc

const SeparatorH2Style = styled.div`
  h2 {
    padding-bottom: 0.6em;
    margin-bottom: 0.6em;
    border-bottom: ${(props) =>
      "1px solid " + props.theme.orbit.backgroundSeparator};
  }
`

export default SeparatorH2Style
