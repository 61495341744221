import React from "react"

import Layout from "components/layout"
import ServiceUpdateDetail from "components/service-update/service-update-detail"

const ServiceUpdatePage = () => (
  <Layout
    title="Service Updates"
    description="Find out about service cancellations or delays"
  >
    <ServiceUpdateDetail />
  </Layout>
)

export default ServiceUpdatePage
