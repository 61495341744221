import { Unsubscribable } from "rxjs"

import { ServiceUpdate, ServiceUpdateSummary } from "types/service-update"

import { fetchFromAPIBase } from "utils/fetch-utils"

import { EmberApiError, parseError } from "./errors"

interface fetchServiceUpdatesParams {
  preview: boolean
  onSuccess: (data: { result?: ServiceUpdate; trips?: ServiceUpdate[] }) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchServiceUpdates({
  preview,
  onSuccess,
  onError,
  onCompletion = () => null,
}: fetchServiceUpdatesParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/service-updates/?preview=${preview}`,
    useAuthIfAvailable: false,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response as any)
    } else {
      onError(parseError(response))
    }

    onCompletion()
  })
}

interface fetchServiceUpdateSummaryParams {
  preview: boolean
  onSuccess: (data: ServiceUpdateSummary) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchServiceUpdateSummary({
  preview,
  onSuccess,
  onError,
  onCompletion = () => null,
}: fetchServiceUpdateSummaryParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/service-updates/summary/?preview=${preview}`,
    useAuthIfAvailable: false,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response as ServiceUpdateSummary)
    } else {
      onError(parseError(response))
    }

    onCompletion()
  })
}
