// @ts-strict-ignore
import React from "react"

import styled from "styled-components"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"

import Container from "components/container"
import Hero from "components/hero"
import SeparatorH2Style from "components/separator-h2-style"

import { ServiceUpdate } from "types/service-update"

import { useServiceUpdate } from "./service-update-hooks"

const CenteredText = styled.p`
  text-align: center;
`

const TripUpdate = styled.p`
  .expected-delay {
    color: ${(props) => props.theme.orbit.paletteRedNormal};
  }
  .cancelled {
    color: ${(props) => props.theme.orbit.paletteRedNormal};
  }
  .cancelled-stop-name {
    color: ${(props) => props.theme.orbit.paletteInkLight};
    text-decoration: line-through;
  }
`

function buildDetails(
  serviceUpdate: ServiceUpdate,
  tripUpdates: ServiceUpdate[]
) {
  let serviceUpdateMessage = null
  let tripUpdatesMessage = null

  if (serviceUpdate == null && tripUpdates == null) {
    return (
      <CenteredText data-test="all-good-message">
        All services are running. You can track your bus on our{" "}
        <a href="/map">live map</a>.
      </CenteredText>
    )
  }

  if (serviceUpdate) {
    serviceUpdateMessage = (
      <div
        dangerouslySetInnerHTML={{
          __html: serviceUpdate.detailedMessageRendered,
        }}
      ></div>
    )
  }
  if (tripUpdates) {
    tripUpdatesMessage = (
      <Stack>
        <Heading>Trip Updates</Heading>
        {tripUpdates.map((trip: ServiceUpdate) => (
          <TripUpdate
            key={trip.uid}
            dangerouslySetInnerHTML={{
              __html: trip.detailedMessageRendered,
            }}
          ></TripUpdate>
        ))}
      </Stack>
    )
  }
  return (
    <>
      <SeparatorH2Style>{serviceUpdateMessage}</SeparatorH2Style>
      <SeparatorH2Style>{tripUpdatesMessage}</SeparatorH2Style>
    </>
  )
}

const ServiceUpdateDetail = () => {
  const { serviceUpdate, serviceUpdateLoaded, tripUpdates } = useServiceUpdate()

  return (
    <>
      <Hero title="Service Updates" />
      <Container size="medium">
        {serviceUpdateLoaded && buildDetails(serviceUpdate, tripUpdates)}
      </Container>
    </>
  )
}

export default ServiceUpdateDetail
